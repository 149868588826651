
body, html {
    margin: 0;
    padding: 0;
    background: rgb(37, 34, 34);
    
}

.App {
    text-align: center;
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-logo {
    height: 30vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 5s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#loader {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(155, 155, 155, 0.7);
}

/* .clickable {
    cursor: pointer;
} */


a{
    text-decoration: none;
    color: white;
}

a:hover{
    color: black;
    text-decoration: none;
}

.background-img{
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.1)),
    url(../../images/graffiti-colorful-wall-s.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

}

.footer-img {
    width: 155px;
    height: 155px;
}

.logo-img{
    width: 165px;
    height: 175px;
}


.info-container{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    background-color: white ;
    padding: 0rem calc((100vw - 1300px) / 2);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    color: black; 
}

.map-wrap{
    margin-top: 1rem;
    max-height: 100%;;
    max-width: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.info-wrap{
    width: 100%;
    height: 100%;
    padding: 2.5rem 0.5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
}

.info-wrap h1{
    font-size: clamp(1.5rem, 4vw, 2rem);
    letter-spacing: 2px;
    text-transform: uppercase;

}

.info-wrap hr{
    width: 65%;
}

.info-wrap p{
    font-size: clamp(0.75rem, 2vw, 1rem);
    letter-spacing: 1px;
    padding: 0.5rem 1.5rem;
}

@font-face {
    font-family: 'Sedgwick Ave Display';
    src: url(../../font/SedgwickAveDisplay-Regular.ttf);
    font-style: normal;
    font-weight: 300;
}

@media only screen and (min-width: 280px) and (max-width: 820px) {
    .background-img{
        background-attachment: scroll;
        background-position-x: 50%;
        background-position-y: 0%;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 300% 100%;
    }
}

@media only screen and (min-width: 812px) and (orientation: landscape) {

    .info-container{
        display: grid;
        flex-direction: column;
        height: inherit;
    }

    .info-wrap{
        align-items: center;
    }

    
}

@media only screen and (min-width: 375px) and (orientation: portrait) {
    .info-container{
        display: grid;
        flex-direction: column;
        height: inherit;
    }

    .map-wrap{
        width: 90vw;
    }

    .info-wrap{
        align-items: center;
    }

}

@media only screen and (min-width: 568px) and (orientation: landscape) {
    .info-container{
        display: grid;
        flex-direction: column;
        height: inherit;
    }

    .map-wrap{
        width: 90vw;
    }

    .info-wrap{
        align-items: center;
    }
}

@media only screen and (min-width: 320px) and (orientation: portrait) {
    .info-container{
        display: grid;
        flex-direction: column;
        height: inherit;
    }

    .map-wrap{
        padding: 0.5rem 2rem;
        width: 90vw;
    }

    .info-wrap{
        align-items: center;
        
    }
}

@media only screen and (min-width: 280px) and (orientation: portrait){
    .info-container{
        display: grid;
        flex-direction: column;
        height: inherit;
    }

    .map-wrap{
        padding: 0.5rem 2rem;
        width: 90vw;
    }

    .map-wrap iframe{
        width: 250px;
        height: 275px;
    }

    .info-wrap{
        align-items: center;
    }
}

@media only screen and (min-width: 1366px) and (orientation: landscape) {
    .info-container{
        width: inherit;
    }
}

@media only screen and (min-width: 280px) and (orientation: portrait) {

    .pdf-section{
        width: '100%';
        height: '100%';

    }

}

